.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
.no-outline {
  outline: none;
}

.no-outline:focus {
  outline: none;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(241, 241, 241) rgb(145, 145, 145);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  height: 9px;
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: rgb(243, 243, 243);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(182, 181, 181);
}

input:checked ~ .dot {
  background-color: rgb(10, 189, 10) !important;
}
.rs-picker-toggle-textbox {
  color: black !important;
}

.rs-picker-toggle-textbox::before {
  color: black !important;
}
.rs-picker-toggle-textbox:hover {
  color: black !important;
}
.rs-picker-toggle-value {
  color: black !important;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000000;
  color: #000000;
}
.rs-picker
  .rs-picker-daterange
  .rs-picker-default
  .rs-picker-toggle-wrapper
  .rs-picker-placement-bottom-end
  .rs-picker-cleanable
  .rs-picker-toggle
  .rs-btn
  .rs-btn-default {
  border-color: #2b313c26 !important;
  border-color: var(--rs-input-focus-border);
}
.rs-picker
  .rs-picker-daterange
  .rs-picker-default
  .rs-picker-toggle-wrapper
  .rs-picker-placement-bottom-end
  .rs-picker-cleanable
  .rs-picker-toggle
  .rs-btn
  .rs-btn-default
  :hover {
  border-color: #39c8c5 !important;
  border-color: var(--rs-input-focus-border);
}
.rs-picker.rs-picker-focused,
.rs-picker:not(.rs-picker-disabled):hover {
  border-color: #39c8c5 !important;
  border-color: var(--rs-input-focus-border);
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled)
  .rs-picker-toggle:hover
  .rs-btn-primary {
  border-color: #39c8c5 !important;
  border-color: var(--rs-input-focus-border);
}
.rs-btn-primary {
  color: #fff;
  color: var(--rs-btn-primary-text);
  background-color: #39c8c5 !important;
  background-color: var(--rs-btn-primary-bg);
  border: none;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-selected-start {
  background-color: #39c8c5 !important;
}
.rs-picker-menu {
  z-index: 20 !important;
}

a:hover {
  text-decoration: none !important;
}
a:focus,
a:hover {
  color: var(--rs-text-link-hover);
  text-decoration: none !important;
}
