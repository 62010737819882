@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

/* a:focus {
  color: white !important;
  text-decoration: none !important;
} */

a:hover {
  text-decoration: none !important;
}
a:focus,
a:hover {
  color: inherit !important;
  text-decoration: none !important;
}

body {
  margin: 0;
  font-family: Inter, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
